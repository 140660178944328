<template>
  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
      <div class="nav">
        <div class="sb-sidenav-menu-heading"><i class="fas fa-gauge-high me-2"></i>ΓΕΝΙΚΑ</div>
        <router-link :to="{'name': 'dashboard'}" :class="'nav-link ' + (this.$route.name === 'dashboard' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-home"></i></div>{{ menuTitles.dashboard.title }}
        </router-link>
        <div class="sb-sidenav-menu-heading" v-if="privileges.canSeeContainersDatatable || privileges.canSeeProductsDatatable || privileges.canSeeLiquidsDatatable"><i class="fas fa-industry me-2"></i>ΕΡΓΟΣΤΑΣΙΟ</div>
        <router-link :to="{'name': 'containers'}" :class="'nav-link ' + (this.$route.name === 'containers' ? ' active': '')" v-if="privileges.canSeeContainersDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-glass-whiskey"></i></div>{{ menuTitles.containers.title }}
        </router-link>
        <router-link :to="{'name': 'products'}" :class="'nav-link ' + (this.$route.name === 'products' ? ' active': '')" v-if="privileges.canSeeProductsDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-champagne-glasses"></i></div>{{ menuTitles.products.title }}
        </router-link>
        <router-link :to="{'name': 'liquids'}" :class="'nav-link ' + (this.$route.name === 'liquids' ? ' active': '')" v-if="privileges.canSeeLiquidsDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-wine-bottle"></i></div>{{ menuTitles.liquids.title }}
        </router-link>
        <div class="sb-sidenav-menu-heading" v-if="privileges.canSeeTransfersDatatable || privileges.canSeeLogsDatatable"><i class="fas fa-rectangle-list me-2"></i>ΙΣΤΟΡΙΚΟ</div>
        <router-link :to="{name: 'transfers'}" :class="'nav-link ' + (this.$route.name === 'transfers' ? ' active': '')" v-if="privileges.canSeeTransfersDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-arrow-right-arrow-left me-2"></i></div>Κινήσεις
        </router-link>
        <router-link :to="{name: 'logs'}" :class="'nav-link ' + (this.$route.name === 'logs' ? ' active': '')" v-if="privileges.canSeeLogsDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-clipboard-list me-2"></i></div>Αναφορές
        </router-link>
        <div class="sb-sidenav-menu-heading" v-if="privileges.canSeeAdminsDatatable">
          <i class="fas fa-shield-halved me-2"></i>ΔΙΑΧΕΙΡΙΣΗ
        </div>
        <router-link :to="{name: 'admins'}" :class="'nav-link ' + (this.$route.name === 'admins' ? ' active': '')" v-if="privileges.canSeeAdminsDatatable">
          <div class="sb-nav-link-icon"><i class="fas fa-user-shield me-2"></i></div>Διαχειριστές
        </router-link>
      </div>
    </div>
    <div class="sb-sidenav-footer">
      <div class="small">{{ currentTime }}</div>
    </div>
  </nav>
</template>

<style scoped>

</style>

<script>


  import menuTitles from "@/assets/js/Library/MenuTitles";
  import Privileges from "@/assets/js/Library/Privileges";

  export default {
    computed: {
      menuTitles() {
        return menuTitles
      }
    },
    data() {
      return {
        privileges: {
          canSeeContainersDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_SEE_CONTAINERS_DATATABLE.value) === Privileges.containers.CAN_SEE_CONTAINERS_DATATABLE.value,
          canSeeProductsDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_SEE_PRODUCTS_DATATABLE.value) === Privileges.products.CAN_SEE_PRODUCTS_DATATABLE.value,
          canSeeLiquidsDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.liquids.CAN_SEE_LIQUIDS_DATATABLE.value) === Privileges.liquids.CAN_SEE_LIQUIDS_DATATABLE.value,
          canSeeTransfersDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.transfers.CAN_SEE_TRANSFERS_DATATABLE.value) === Privileges.transfers.CAN_SEE_TRANSFERS_DATATABLE.value,
          canSeeLogsDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.logs.CAN_SEE_LOGS_DATATABLE.value) === Privileges.logs.CAN_SEE_LOGS_DATATABLE.value,
          canSeeAdminsDatatable: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_SEE_ADMINS_DATATABLE.value) === Privileges.admins.CAN_SEE_ADMINS_DATATABLE.value,
        },
        ip: localStorage.getItem('ip'),
        currentTime: this.getCurrentTime(),
      }
    },
    mounted() {
      this.updateClock();
    },
    methods: {
      getCurrentTime() {
        const now = new Date();
        return now.toLocaleString('el-GR');
      },
      updateClock() {
        setInterval(() => {
          this.currentTime = this.getCurrentTime();
        }, 1000);
      },
    }
  }
</script>