<template>
  <div class="modal fade" ref="blendingModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-shuffle me-2"></i>Σύμμιξη προϊόντων στη δεξαμενή <b><i>{{ container_name }}</i></b></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="row d-none" ref="inputContainersBlend">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="blended-containers">Δεξαμενές</label>
                    <vue-select id="blended-containers" multiple
                                :options="containers"
                                v-model="form.containers"
                                @option:selected="selectContainer('blend')"
                                @option:deselected="filterContainers('')"
                                @keyup="filterContainers($event.target.value)"
                    >
                      <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                      </template>
                    </vue-select>
                  </div>
                </div>
              </div>

              <div class="row" v-for="(container, index) in form.containers" :key="index">

                <div class="col-9">
                  <div class="mb-3">
                    <label :for="`blended-product-quantity-${index}`">Ποσότητα από: <i><b>{{ container.name }}</b></i></label>
                    <input @keydown.enter.prevent="handleEnterKey" type="number" :id="`blended-product-quantity-${index}`" step=".01"
                           class="form-control" v-model="form.containers[index].quantity">
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <label :for="`blended-product-waste-${index}`">Φύρα</label>
                    <input @keydown.enter.prevent="handleEnterKey" type="number" :id="`blended-product-waste-${index}`" step=".01"
                           class="form-control" v-model="form.containers[index].waste" value="0">
                  </div>
                </div>

              </div>
            </div>

            <div class="row" ref="inputNotes">
              <div class="col">
                <div class="mb-3">
                  <label for="container-notes">Σημειώσεις</label>
                  <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                            v-model="form.notes"></textarea>
                </div>
              </div>
            </div>


          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div ref="modalButton"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'BlendingModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  created() {
    window.blend = this.blend;
  },
  data() {
    return {
      title: '',
      privileges: {
        canBlend: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_BLEND_PRODUCT.value) === Privileges.actions.CAN_BLEND_PRODUCT.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
      },
      containers: [
        {value: 0, label: ''}
      ],
      container_name: '',
      modalRef: null,
      privilege: null,
      container: null,
      form: {
        container_id: 0,
        product_id: 0,
        notes: '',
        containers: []
      },
      error: {
        response: {
          status: 0,
          data: {errors: {}}
        },
      },
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.blendingModal);
  },
  methods: {
    prepareBlend(container_id, container_name, product_id, level) {

      if (!this.privileges.canBlend) {
        this.error.response.status = 401;
        this.error.response.data.errors = {};
        ErrorHandle(this.error);
        return;
      }

      Swal.close();
      this.$refs.modalButton.innerHTML = ModalButtons.blend(container_id, product_id, level)

      this.$refs.inputContainersBlend.classList.remove('d-none')
      this.$refs.inputNotes.classList.remove('d-none')
      this.clearForm();

      this.form.container_id = container_id;
      this.form.product_id = product_id;

      this.filterContainers('');

      this.modalRef.show();
    },
    blend(container_id, product_id, level) {

      if (!this.privileges.canBlend) {
        this.error.response.status = 401;
        this.error.response.data.errors = {};
        ErrorHandle(this.error);
        return;
      }

      let data = new FormData();
      data.append('container_id', container_id);
      data.append('notes', this.form.notes);
      if (product_id) {
        data.append(`containers[0][id]`, container_id)
        data.append(`containers[0][quantity]`, level)
        data.append(`containers[0][waste]`, 0)
      }
      this.form.containers.forEach((container, index) => {
        let indexPlace = index
        if (product_id) {
          indexPlace = (index + 1)
        }
        data.append(`containers[${indexPlace}][id]`, container.value)
        data.append(`containers[${indexPlace}][quantity]`, container.quantity)
        data.append(`containers[${indexPlace}][waste]`, container.waste)
      })

      axios.post(apiRoutes.actions.blend, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then((response) => {
        Swal.fire({
          title: 'Επιτυχία',
          icon: 'success',
          text: response.data.message,
          allowEnterKey: true,
        });
        this.closeModal();
        this.$emit('data-modified');
      }).catch((error) => ErrorHandle(error));
    },
    filterContainers(value) {
      if (!this.privileges.canFilterContainers) {
        this.error.response.status = 401;
        ErrorHandle(this.error);
        return;
      }
      axios.post(apiRoutes.containers.filter, {
        type: 'blend',
        name: value,
        container_id: this.form.container_id,
        used_containers: this.form.containers,
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.containers = [];
        response.data.forEach((item) => {
          this.containers.push({
            value: item.id,
            label: `${item.container_name} (${item.product_name}) (${item.level} / ${item.capacity})`,
            name: item.container_name
          })
        })
      }).catch(error => ErrorHandle(error));
    },
    selectContainer() {
      this.filterContainers('');
      this.form.containers.forEach((container) => {
        if (container.quantity === undefined) {
          container.quantity = 0;
        }
        if (container.waste === undefined) {
          container.waste = 0;
        }
      })
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.container_id = 0;
      this.form.notes = '';
      this.form.containers.forEach((container) => {
        if (container.quantity) {
          container.quantity = 0;
        }
      })
      this.form.containers = [];
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style scoped>

</style>