<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i :class="`fas ${menuTitles.liquids.icon} me-2`"></i>{{ menuTitles.liquids.title }}</h1>
      <BreadcrumbComponent :title="menuTitles.liquids.title" :icon="menuTitles.liquids.icon"/>

      <LiquidsTable ref="liquidsTable"/>

    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import menuTitles from "../../assets/js/Library/MenuTitles";
import LiquidsTable from "@/views/Liquids/LiquidsTable.vue";
import BreadcrumbComponent from "@/components/LayoutComponents/BreadcrumbComponent.vue";

export default {
  name: 'LiquidsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {BreadcrumbComponent, AdminLayout, LiquidsTable},
}
</script>
