<template>
  <section class="h-100">
    <div class="container h-100">
      <div class="row justify-content-sm-center h-100">
        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <div class="text-center my-5">
            <img src="@/assets/img/logo-500x500.png" alt="logo" width="100">
            <h1 class="text-white">Tankometric</h1>
          </div>
          <div class="card shadow-lg">
            <div class="card-body p-5">
              <h1 class="fs-4 card-title fw-bold mb-4">Επαναφορά Κωδικού</h1>
              <form>

                <div class="mb-3">
                  <label class="mb-2 text-muted" for="email">Κωδικός Πρόσβασης</label>
                  <input @keydown.enter.prevent="resetPassword" id="password" type="password" class="form-control"
                         name="password" value=""
                         v-model="password"
                         required autofocus>
                </div>

                <div class="mb-3">
                  <label class="mb-2 text-muted" for="email">Επιβεβαίωση Κωδικού Πρόσβασης</label>
                  <input @keydown.enter.prevent="resetPassword" id="password-confirmation" type="password"
                         class="form-control" name="password-confirmation" value=""
                         v-model="password_confirmation"
                         required autofocus>
                </div>
                <div class="d-grid gap-2 mx-auto">
                  <button type="button" class="btn btn-primary btn-block" @click="resetPassword">Αλλαγή Κωδικού</button>
                </div>
              </form>
            </div>
          </div>
          <div class="text-center mt-5 text-muted">
            Copyright &copy; {{ date }} &mdash; Tankometric
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";

export default {
  name: "ResetPasswordView",
  beforeRouteEnter(to, from, next) {

    if (!to.params.token || to.params.token.length !== 64 || !to.params.email) {
      next({name: 'login'});
      return;
    }

    const data = new FormData();
    data.append('email', to.params.email);
    data.append('token', to.params.token);

    axios.post(ApiRoutes.checkPasswordRequest, data, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
    }).then((response) => {
      if (response.status === 200) {
        next();
        return;
      }
      next({name: 'login'});
    }).catch(() => {
      next({name: 'login'});
    });

  },
  data() {
    return {
      dateStarted: 2024,
      date: null,
      password: '',
      password_confirmation: '',
      showPassword: false
    }
  },
  mounted() {
    document.body.classList.add('bg-dark');

    const currentYear = new Date().getFullYear();

    this.date = currentYear;
    if (currentYear > this.dateStarted) {
      this.date = this.dateStarted + ' - ' + currentYear;
    }
  },
  methods: {
    resetPassword() {
      if (this.password !== this.password_confirmation) {

        Swal.fire({
          icon: 'error',
          title: 'Σφάλμα',
          text: 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι'
        })
        return;
      }

      const data = new FormData();
      data.append('token', this.$route.params.token);
      data.append('email', this.$route.params.email);
      data.append('password', this.password);
      data.append('password_confirmation', this.password_confirmation);

      axios.post(ApiRoutes.resetPassword, data, {
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')},
      }).then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Επιτυχία',
          text: response.data.message
        })
        this.$router.push({name: 'login'});
      }).catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: 'Κάτι πήγε στραβά'
          })
        } else if (error.response.status === 422) {
          console.log(error.response)
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
          })
        } else if (error.response.status === 429) {
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: 'Υπερβολικά πολλά αιτήματα! Προσπαθήστε μετά από μερικά λεπτά!'
          })
        }
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  }
}
</script>

<style scoped>

</style>