<template>
  <div class="modal fade" ref="logsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-clipboard-list me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <span class="me-2"><b>Ενέργεια:</b></span>
              <span><i ref="formTypeOfMove"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Ημερομηνία:</b></span>
              <span><i ref="formDate"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Λεπτομέρειες:</b></span>
              <span><i ref="formDetails"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Σημειώσεις:</b></span>
              <span><i ref="formNotes"></i></span>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Κλείσιμο
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'LogsModal',
  created() {
    window.info = this.info
  },
  data() {
    return {
      privileges: {
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.logs.CAN_SEE_LOGS_INFO.value) === Privileges.logs.CAN_SEE_LOGS_INFO.value,
      },
      title: 'Αναλυτικά στοιχεία αναφοράς',
      button: null,
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: '',
        liquids: [],
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.logsModal);
  },
  methods: {
    info(id) {
      if (!this.privileges.canSeeLogsInfo) {
        return;
      }

      axios.post(apiRoutes.logs.view, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.$refs.formDate.innerHTML = `<i><i class="fas fa-regular fa-calendar ms-2"></i> ${response.data.created_date} <i class="fas fa-regular fa-clock ms-2"></i> ${response.data.created_time}</i>`;

        let color;
        if (response.data.log_type === 1) {
          color = 'bg-danger';
        }
        if (response.data.log_type === 2) {
          color = 'bg-primary';
        }
        if (response.data.log_type === 3) {
          color = 'bg-success';
        }
        if (response.data.log_type === 4) {
          color = 'bg-info';
        }
        this.$refs.formTypeOfMove.innerHTML = `<span class="badge rounded-pill ${color}">${response.data.log_type_name}</span>`;

        this.$refs.formDetails.innerHTML = response.data.details ? response.data.details : '-';
        this.$refs.formNotes.innerHTML = response.data.notes ? response.data.notes : '-';

        this.openModal();
      })
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.name = '';
      this.form.liquids = [];
    }
  }
}
</script>

<style scoped>

</style>