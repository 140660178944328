const menuTitles = {
    dashboard: {title: 'Αρχική', icon: 'fa-house'},
    containers: {title: 'Δεξαμενές', icon: 'fa-whiskey-glass'},
    products: {title: 'Προϊόντα', icon: 'fa-champagne-glasses'},
    liquids: {title: 'Συστατικά', icon: 'fa-wine-bottle'},
    transfers: {title: 'Κινήσεις', icon: 'fa-arrow-right-arrow-left'},
    logs: {title: 'Αναφορές', icon: 'fa-clipboard-list'},
    admins: {title: 'Διαχειριστές', icon: 'fa-user-shield'},
};

export default menuTitles;