<template>
  <div class="modal fade" ref="bottlingModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-bottle-droplet me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row" ref="inputQuantity">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid" ref="quantityTitleRef">Ποσότητα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-liquid" step=".01" class="form-control"
                         value="0" v-model="form.quantity">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid">Φύρα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-waste" step=".01" class="form-control"
                         value="0" v-model="form.waste">
                </div>
              </div>
            </div>

            <div class="row" ref="inputNotes">
              <div class="col">
                <div class="mb-3">
                  <label for="container-notes">Σημειώσεις</label>
                  <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                            v-model="form.notes"></textarea>
                </div>
              </div>
            </div>


          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'BottlingModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  created() {
    window.bottle = this.bottle;
  },
  data() {
    return {
      title: '',
      button: null,
      privileges: {
        canBottle: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_BOTTLE_PRODUCT.value) === Privileges.actions.CAN_BOTTLE_PRODUCT.value
      },
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        quantity: 0,
        waste: 0,
        notes: ''
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.bottlingModal);
  },
  methods: {
    prepareBottle(id, current, max) {
      if (!this.privileges.canBottle) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();

      this.title = 'Εμφιάλωση';
      this.button = ModalButtons.bottle(id)

      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα (${current} / ${max})`

      this.clearForm();

      this.openModal();
    },
    bottle(id) {
      if (!this.privileges.canBottle) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const data = new FormData();
      data.append('container_id', id);
      data.append('quantity', this.form.quantity);
      data.append('waste', this.form.waste);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.bottling, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.closeModal();
          this.$emit('data-modified');
        }
      }).catch((error) => ErrorHandle(error));
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.quantity = 0;
      this.form.waste = 0;
      this.form.notes = '';
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style scoped>

</style>