<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col"><i class="fas fa-table me-1"></i>Πίνακας Διαχείρισης Προϊόντων</div>
        <div class="col">
          <button @click="prepareStore" class="btn btn-outline-dark float-end"><i class="fas fa-plus me-2"></i>Προσθήκη Νέου</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-xxl-3 col-xl-4 col-md-4">
          <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
            <option value="10">10 Εγγραφές / Σελίδα</option>
            <option value="25">25 Εγγραφές / Σελίδα</option>
            <option value="50">50 Εγγραφές / Σελίδα</option>
            <option value="100">100 Εγγραφές / Σελίδα</option>
          </select>
        </div>
        <div class="col-xxl-7 col-xl-4 col-md-4"></div>
        <div class="col-xxl-2 col-xl-4 col-md-4">
          <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchData">
        </div>
      </div>

      <table class="table table-hover">
        <thead>
        <tr>
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="name" name="Όνομα" icon="fa-quote-left" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="total" name="Λίτρα Ένυδρα" icon="fa-warehouse" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="pure" name="Λίτρα Άνυδρα" icon="fa-warehouse" @sort="sort" />
          <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="waste" name="Φύρα" icon="fa-faucet-drip" @sort="sort" />
          <th class="text-center" scope="col"><i class="fas fa-cogs me-2"></i><span class="d-none d-xl-inline">Ενέργειες</span></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.nameSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.totalSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.pureSearch" @input="fetchData"></th>
          <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="table.wasteSearch" @input="fetchData"></th>
          <th class="text-center"><i class="fas fa-gear me-1"></i></th>
        </tr>
        </tfoot>
        <tbody>
        <tr v-if="products.length === 0">
          <td colspan="6" class="text-center">Δεν βρέθηκαν εγγραφές!</td>
        </tr>
        <tr v-for="product in products" :key="product.id">
          <td class="text-center">{{ product.name }}</td>
          <td class="text-center">{{ product.total }}</td>
          <td class="text-center">{{ product.pure }}</td>
          <td class="text-center">{{ product.waste }}</td>
          <td class="text-center"><span v-html="getActionButtons(product.id)"></span></td>
        </tr>
        </tbody>
      </table>

      <Footer :search-value="table.search" :paging-object="paging" :data-length="products.length" @page-changed="changePage"/>
    </div>
  </div>

  <ProductsModal ref="productsModal" @data-modified="fetchData"/>

</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";
import PaginatorComponent from "@/components/TableComponents/Paginator.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import TableColumn from "@/components/TableComponents/TableColumn.vue";
import Footer from "@/components/TableComponents/Footer.vue";
import ProductsModal from "@/views/Products/ProductsModal.vue";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";

export default {
  name: 'ProductsTable',
  components: {ProductsModal, Footer, TableColumn, VueDatePicker, PaginatorComponent, AdminLayout},
  data() {
    return {
      privileges: {
        canSeeProductsData: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_SEE_PRODUCTS_DATATABLE.value) === Privileges.products.CAN_SEE_PRODUCTS_DATATABLE.value,
        canStoreProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_STORE_PRODUCTS.value) === Privileges.products.CAN_STORE_PRODUCTS.value,
        canEditProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_EDIT_PRODUCTS.value) === Privileges.products.CAN_EDIT_PRODUCTS.value,
        canUpdateProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_UPDATE_PRODUCTS.value) === Privileges.products.CAN_UPDATE_PRODUCTS.value,
        canDestroyProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.products.CAN_DESTROY_PRODUCTS.value) === Privileges.products.CAN_DESTROY_PRODUCTS.value,
      },
      products: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'name',
        sort_dir: 'asc',
        perPage: 10,
        page: 1,
        nameSearch: '',
        totalSearch: '',
        pureSearch: '',
        wasteSearch: '',
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.privileges.canSeeProductsData) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);
      data.append('nameSearch', this.table.nameSearch);
      data.append('totalSearch', this.table.totalSearch);
      data.append('pureSearch', this.table.pureSearch);
      data.append('wasteSearch', this.table.wasteSearch);

      axios.post(ApiRoutes.products.datatable, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        this.products = response.data.results.data;
        this.paging.from = response.data.results.from;
        this.paging.to = response.data.results.to;
        this.paging.current_page = response.data.results.current_page;
        this.paging.last_page = response.data.results.last_page;
        this.paging.per_page = response.data.results.per_page;
        this.paging.total = response.data.results.total;
        this.paging.totalRecords = response.data.totalRecords;
      });
    },
    getActionButtons(id) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;
      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (this.privileges.canEditProducts) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
      }
      if (this.privileges.canDestroyProducts) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
      }
      dropdown += `</ul>`;
      return dropdown;
    },
    prepareStore() {
      this.$refs.productsModal.prepareStore();
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchData(); // Call method to fetch data with new perPage value
    },
    changePage(page) {
      this.table.page = page;
      this.fetchData();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchData();
    }
  }
}
</script>

<style>
th {
  cursor: pointer;
}
</style>
