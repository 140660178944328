<template>
  <div class="spinner-container d-none" ref="loadingSpinner">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <NavbarComponent/>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <SidebarComponent/>
    </div>
    <div id="layoutSidenav_content">
      <main>
        <slot/>
      </main>
      <FooterComponent/>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/LayoutComponents/NavbarComponent.vue";
import FooterComponent from "@/components/LayoutComponents/FooterComponent.vue";
import SidebarComponent from "@/components/LayoutComponents/SidebarComponent.vue";

import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.min.css';


export default {
  name: 'AdminLayout',
  components: {
    NavbarComponent, FooterComponent, SidebarComponent
  },
  mounted() {
    document.body.classList.remove('bg-dark');

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      if (window.innerWidth <= 768) {
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        }
      }
      sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
    }

  }
}

</script>

<style scoped>
@import "/src/assets/css/styles.css";
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's above other elements on the page */
}
.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>