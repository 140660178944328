<template>
  <div class="modal fade" ref="transferringModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-arrow-turn-up me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="row" ref="inputContainer">
              <div class="col">
                <div class="mb-3">
                  <label for="transfer-container">Προς Δεξαμενή</label>
                  <vue-select id="transfer-container"
                              :options="containers"
                              v-model="container"
                              @option:selected="selectContainer"
                              @option:deselected="selectContainer"
                              @keyup="filterContainers($event.target.value)"
                  >
                    <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                    </template>
                  </vue-select>
                </div>
              </div>
            </div>

            <div class="row" ref="inputQuantity">
              <div class="col">
                <div class="mb-3">
                  <label for="transfer-quantity" ref="quantityTitleRef">Ποσότητα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="transfer-quantity" step=".01" class="form-control" v-model="form.quantity">
                </div>
              </div>
            </div>

            <div class="row" ref="inputWaste">
              <div class="col">
                <div class="mb-3">
                  <label for="container-liquid">Φύρα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-waste" step=".01" class="form-control"
                         value="0" v-model="form.waste">
                </div>
              </div>
            </div>

            <div class="row" ref="inputNotes">
              <div class="col">
                <div class="mb-3">
                  <label for="container-notes">Σημειώσεις</label>
                  <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                            v-model="form.notes"></textarea>
                </div>
              </div>
            </div>


          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <button id="modal-confirm-button" class="btn btn-primary" @click="transfer"><i class="fas fa-right-left me-1"></i> Μεταφορά</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'TransferringModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  created() {
    window.transfer = this.transfer;
  },
  data() {
    return {
      title: '',
      privileges: {
        canTransfer: (BigInt(localStorage.getItem('privilege')) & Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value) === Privileges.actions.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
      },
      containers: [
        {value: 0, label: ''}
      ],
      modalRef: null,
      privilege: null,
      container: {},
      form: {
        container1_id: 0,
        container2_id: 0,
        product_id: 0,
        quantity: 0,
        waste: 0,
        notes: ''
      },
      error: {
        response: {
          status: 0,
          data: {errors: {}}
        },
      },
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.transferringModal);
  },
  methods: {
    prepareTransfer(container_id, product_id, current, max) {

      if (!this.privileges.canTransfer) {
        this.error.response.status = 401;
        this.error.response.data.errors = {};
        ErrorHandle(this.error);
        return;
      }
      Swal.close();
      this.clearForm();
      this.filterContainers('');

      this.title = 'Μεταφορά προϊόντος σε άλλη δεξαμενή';

      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα (τρέχουσα δεξαμενή: ${current} / ${max})`;

      this.form.container1_id = container_id;
      this.form.product_id = product_id;

      this.openModal();
    },
    transfer() {

      if (!this.privileges.canTransfer) {
        this.error.response.status = 401;
        this.error.response.data.errors = {};
        ErrorHandle(this.error);
        return;
      }

      if (this.form.container2_id <= 0) {
        console.log(JSON.stringify(this.form))
        this.error.response.status = 422;
        this.error.response.data.errors = {container: ["Η δεξαμενή δεν είναι έγκυρη"]};
        ErrorHandle(this.error);
        return;
      }

      if (this.form.quantity <= 0) {
        this.error.response.status = 422;
        this.error.response.data.errors = {quantity: ["Η ποσότητα δεν είναι έγκυρη"]};
        ErrorHandle(this.error);
        return;
      }

      const data = new FormData();
      data.append('container1_id', this.form.container1_id);
      data.append('container2_id', this.form.container2_id);
      data.append('quantity', this.form.quantity);
      data.append('waste', this.form.waste);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.transfer, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.closeModal();
          this.$emit('data-modified');
        }
      }).catch((error) => ErrorHandle(error));
    },
    filterContainers(value) {
      if (!this.privileges.canFilterContainers) {
        this.error.response.status = 401;
        ErrorHandle(this.error);
        return;
      }
      axios.post(apiRoutes.containers.filter, {
        type: 'transfer',
        name: value,
        container_id: this.form.container2_id,
        product_id: this.form.product_id,
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.containers = [];
        response.data.forEach((item) => {
          this.containers.push({
            value: item.id,
            label: `${item.container_name} (${item.product_name}) (${item.level} / ${item.capacity})`,
          })
        })
      }).catch(error => ErrorHandle(error));
    },
    selectContainer() {

      this.form.container2_id = this.container?.value ?? 0;
      this.filterContainers('');
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.quantity = 0;
      this.form.waste = 0;
      this.form.notes = '';
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style scoped>

</style>