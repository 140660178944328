<template>
  <div class="modal fade" ref="reportModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-wine-bottle me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <label for="report-date" class="form-label">Ημερομηνία</label>
              <VueDatePicker id="report-date"
                             v-model="form.date"
                             locale="el"
                             cancelText="Ακύρωση"
                             selectText="Επιλογή"
                             placeholder="Επιλέξτε Εύρος"
                             range
                             :enable-time-picker="false"
              ></VueDatePicker>
            </div>

            <div class="mb-3">
              <label for="report-containers" class="form-label">Δεξαμενές</label>
              <vue-select id="report-containers" multiple
                          :options="containers"
                          v-model="form.containers"
                          @option:selected="filterContainers('')"
                          @option:deselected="filterContainers('')"
                          @keyup="filterContainers($event.target.value)"
              >
                <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                </template>
              </vue-select>
            </div>

            <div class="mb-3">
              <label for="report-types">Κινήσεις</label>
              <vue-select id="report-types"
                          :options="types"
                          v-model="form.types"
                          multiple
                          :searchable="false"
              >
              </vue-select>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";
import VueDatePicker from '@vuepic/vue-datepicker';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import Swal from "sweetalert2";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import Modals from "@/assets/js/Library/Modals";


export default {
  name: 'ReportModal',
  components: {VueSelect, VueDatePicker},
  created() {
    window.generateReport = this.generateReport
  },
  data() {
    return {
      privileges: {
        canSeeTransfersInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value) === Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value,
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.logs.CAN_SEE_LOGS_INFO.value) === Privileges.logs.CAN_SEE_LOGS_INFO.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
      },
      title: 'Δεδομένα προς εξαγωγή',
      button: null,
      modalRef: null,
      privilege: null,
      form: {
        containers: [],
        product: null,
        date: null,
        types: [],
      },
      types: [],
      containers: [],
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.reportModal);
    this.button = Modals.report;
    this.getTransferTypes();
  },
  methods: {
    filterContainers(value) {
      if (!this.privileges.canFilterContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.containers.filter, {
        type: 'generic',
        name: value,
        used_containers: this.form.containers
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.containers = [];
        response.data.forEach((item) => {
          this.containers.push({
            value: item.id,
            label: `${item.container_name} (${item.product_name}) (${item.level} / ${item.capacity})`,
            name: item.container_name
          })
        })
      }).catch(error => ErrorHandle(error));
    },
    generateReport() {
      let data = new FormData();
      let formattedDate = [];
      if (this.form.date && this.form.date[0] && this.form.date[1]) {
        formattedDate = [this.form.date[0].toLocaleDateString(), this.form.date[1]?.toLocaleDateString()]
      }

      data.append('date', formattedDate ?? [])
      this.form.containers.forEach((container, index) => {
        data.append(`containers[${index}]`, container.value)
      })
      this.form.types.forEach((type, index) => {
        data.append(`types[${index}]`, type.value)
      })

      axios.post(apiRoutes.transfers.export.excel, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        responseType: 'blob'
      }).then((response) => {
        this.saveExcelFile(response.data);
        this.closeModal();
      }).catch((error) => errorHandle(error))
    },
    saveExcelFile(data) {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `ΙΣΤΟΡΙΚΟ-${this.getCurrentDate()}.xlsx`; // Change the filename if necessary
      link.click();
    },
    getCurrentDate() {
      // Get the current date
      let currentDate = new Date();

      // Format the date as YYYYMMDDHHmm
      return currentDate.getFullYear() +
          ('0' + (currentDate.getMonth() + 1)).slice(-2) +
          ('0' + currentDate.getDate()).slice(-2) +
          ('0' + currentDate.getHours()).slice(-2) +
          ('0' + currentDate.getMinutes()).slice(-2);
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    getTransferTypes() {
      this.types = []
      axios.get(apiRoutes.transfers.types, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        response.data.forEach((type) => {
          this.types.push({value: type.id, label: type.name})
        })
      })
    },
  }
}
</script>

<style scoped>

</style>