<template>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">Copyright &copy; Tankometric 2024</div>
        <div>
          <div class="text-muted">All rights reserved</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>

</script>

<style scoped>

</style>