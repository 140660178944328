<template>
  <th class="text-center" scope="col" @click="sort">
    <i :class="`fas ${icon} me-2`" :title="name"></i><span>{{ name }}</span>
    <span v-if="(sortField === field && sortDir === 'desc')"><i class="icon fas fa-arrow-down-wide-short ms-2"></i></span>
    <span v-else-if="(sortField === field && sortDir === 'asc')"><i class="icon fas fa-arrow-up-short-wide ms-2"></i></span>
    <span v-else>
      <i class="icon fas fa-arrow-down-long ms-2"></i>
      <i class="icon fas fa-arrow-up-long"></i>
    </span>
  </th>
</template>

<script>
import 'boxicons/css/boxicons.min.css'

export default {
  name: 'TableColumn',
  props: {
    icon: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    sortDir: {
      type: String,
      required: true
    },
  },
  methods: {
    sort() {
      const dir = this.sortDir === 'asc' ? 'desc' : 'asc';
      this.$emit('sort', this.field, dir); // Emitting event with field and newSortDir
    }
  }
}

</script>

<style scoped>
.icon {
  font-size: 12px; /* Adjust the font size of the icon */
  opacity: 0.8; /* Adjust the opacity of the icon */
}
</style>