<template>
  <div class="modal fade" ref="transfersModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-wine-bottle me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <span class="me-2"><b>Κίνηση:</b></span>
              <span><i ref="formTypeOfMove"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Ημερομηνία:</b></span>
              <span><i ref="formDate"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b ref="formContainersTitle"></b></span>
              <span><i ref="formContainers"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Ποσότητα:</b></span>
              <span><i ref="formQuantity"></i></span>
            </div>

            <div class="mb-3">
              <span class="me-2"><b>Σημειώσεις:</b></span>
              <span><i ref="formNotes"></i></span>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'TransfersModal',
  components: {VueSelect},
  created() {
    window.info = this.info
  },
  data() {
    return {
      privileges: {
        canSeeTransfersInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value) === Privileges.transfers.CAN_SEE_TRANSFERS_INFO.value,
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.logs.CAN_SEE_LOGS_INFO.value) === Privileges.logs.CAN_SEE_LOGS_INFO.value,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_FILTER_CONTAINERS.value) === Privileges.containers.CAN_FILTER_CONTAINERS.value,
      },
      title: 'Αναλυτικά στοιχεία κίνησης',
      button: null,
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: '',
        liquids: [],
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.transfersModal);
  },
  methods: {
    info(id) {
      if (!this.privileges.canSeeTransfersInfo) {
        return;
      }

      axios.post(apiRoutes.transfers.view, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.$refs.formDate.innerHTML = `<i><i class="fas fa-regular fa-calendar ms-2"></i> ${response.data.created_date} <i class="fas fa-regular fa-clock ms-2"></i> ${response.data.created_time}</i>`;

        let color;
        if (response.data.transfer_type_id === 1) {
          color = 'bg-primary';
        }
        if (response.data.transfer_type_id === 2) {
          color = 'bg-success';
        }
        if (response.data.transfer_type_id === 3) {
          color = 'bg-info';
        }
        this.$refs.formTypeOfMove.innerHTML = `<span class="badge rounded-pill ${color}">${response.data.transfer_type_name}</span>`;

        this.$refs.formContainersTitle.innerHTML = response.data.container1_name === response.data.container2_name ? 'Δεξαμενή:' : 'Δεξαμενές:';
        this.$refs.formContainers.innerHTML = response.data.container1_name === response.data.container2_name ? response.data.container1_name + ` (${response.data.content_1 ?? '-'})` : response.data.container1_name + ` (${response.data.content_1 ?? '-'}) <i class="fas fa-right-long mx-2"></i>` + response.data.container2_name;

        this.$refs.formQuantity.innerHTML = response.data.quantity + ' Λίτρα' + (parseInt(response.data.transfer_type_id) !== 1 ? ` (Φύρα: ${response.data.waste})` : '');
        this.$refs.formNotes.innerHTML = response.data.notes ? response.data.notes : '-';

        this.openModal();
      })
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.form.id = 0;
      this.form.name = '';
      this.form.liquids = [];
    }
  }
}
</script>

<style scoped>

</style>