<template>
  <div class="modal fade" ref="containersModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i :class="`fas ${menuTitles.containers.icon} me-2`"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="row">
              <div class="col">

                <div class="mb-3">
                  <label for="container-name" class="form-label">Κωδικός Δεξαμενής</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="container-name" v-model="form.name">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="container-capacity" class="form-label">Χωρητικότητα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control"
                         id="container-capacity" min="0"
                         step=".01" @keyup="validateDecimal($event.target)"
                         v-model="form.capacity">
                </div>
              </div>
            </div>

            <div class="row d-none" ref="inputAlcohol">
              <div class="col">
                <div class="mb-3">
                  <label for="container-alcohol">Αλκοολικός Βαθμός</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-alcohol" step=".01"
                         class="form-control"
                         value="0" v-model="form.alcohol">
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";
import menuTitles from "@/assets/js/Library/MenuTitles";

export default {
  name: 'ContainersModal',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueSelect},
  data() {
    return {
      title: '',
      button: null,
      privileges: {
        canStoreContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_STORE_CONTAINERS.value) === Privileges.containers.CAN_STORE_CONTAINERS.value,
        canEditContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_EDIT_CONTAINERS.value) === Privileges.containers.CAN_EDIT_CONTAINERS.value,
        canUpdateContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_UPDATE_CONTAINERS.value) === Privileges.containers.CAN_UPDATE_CONTAINERS.value,
        canDestroyContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.containers.CAN_DESTROY_CONTAINERS.value) === Privileges.containers.CAN_DESTROY_CONTAINERS.value,
      },
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: '',
        capacity: 0,
        alcohol: 0
      }
    }
  },
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.containersModal);
  },
  methods: {
    prepareStore() {
      this.title = 'Προσθήκη νέας δεξαμενής';
      this.button = ModalButtons.create;

      this.clearForm();
      this.openModal();
    },
    store() {
      if (!this.privileges.canStoreContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const containerData = new FormData();
      containerData.append('name', this.form.name);
      containerData.append('capacity', this.form.capacity);

      axios.post(apiRoutes.containers.store, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
          this.closeModal();
          this.$emit('data-modified')
        }
      }).catch((error) => ErrorHandle(error));

    },
    edit(id) {
      if (!this.privileges.canEditContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();


      this.title = 'Διόρθωση στοιχείων δεξαμενής';
      this.button = ModalButtons.update(id);

      axios.post(apiRoutes.containers.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form = response.data;
          if (response.data.product_id > 0) {
            this.$refs.inputAlcohol.classList.remove('d-none')
          }
          this.openModal();
        }
      }).catch((error) => ErrorHandle(error));
    },
    update() {
      if (!this.privileges.canUpdateContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const containerData = {};
      containerData.id = this.form.id;
      containerData.name = this.form.name;
      containerData.alcohol = this.form.alcohol;
      containerData.capacity = this.form.capacity;

      axios.patch(apiRoutes.containers.update, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
          this.closeModal();
          this.$emit('data-modified')
        }
      }).catch((error) => ErrorHandle(error));

    },
    destroy(id) {
      if (!this.privileges.canDestroyContainers) {
        return;
      }
      Swal.close();

      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα τη δεξαμενή! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.containers.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))

          this.$emit('data-modified')

        }
      });
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    },
    validateDecimal(input) {
      input.value = input.value.replace(/,/g, '.');

      return parseFloat(input.value);
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      this.$refs.inputAlcohol.classList.add('d-none')
      this.form.id = 0;
      this.form.name = '';
      this.form.alcohol = '';
    }
  }
}
</script>

<style scoped>

</style>