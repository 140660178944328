const Privileges = {
    containers: {
        CAN_SEE_CONTAINERS_DATATABLE: {id: 1, value: BigInt(1), description: 'Πρόσβαση στα δεδομένα'},
        CAN_STORE_CONTAINERS: {id: 2, value: BigInt(2), description: 'Δημιουργία Νέας'},
        CAN_EDIT_CONTAINERS: {id: 3, value: BigInt(4), description: 'Τροποποίηση'},
        CAN_UPDATE_CONTAINERS: {id: 4, value: BigInt(8), description: 'Ενημέρωση'},
        CAN_DESTROY_CONTAINERS: {id: 5, value: BigInt(16), description: 'Διαγραφή'},
        CAN_FILTER_CONTAINERS: {id: 6, value: BigInt(32), description: 'Αναζήτηση'},
        CAN_SEE_CONTAINERS_CHART: {id: 7, value: BigInt(64), description: 'Πρόσβαση στο γράφημα'},
    },
    products: {
        CAN_SEE_PRODUCTS_DATATABLE: {id: 8, value: BigInt(128), description: 'Πρόσβαση στα δεδομένα'},
        CAN_STORE_PRODUCTS: {id: 9, value: BigInt(256), description: 'Δημιουργία Νέου'},
        CAN_EDIT_PRODUCTS: {id: 10, value: BigInt(512), description: 'Τροποποίηση'},
        CAN_UPDATE_PRODUCTS: {id: 11, value: BigInt(1024), description: 'Ενημέρωση'},
        CAN_DESTROY_PRODUCTS: {id: 12, value: BigInt(2048), description: 'Διαγραφή'},
        CAN_FILTER_PRODUCTS: {id: 13, value: BigInt(4096), description: 'Αναζήτηση'},
        CAN_SEE_PRODUCTS_CHART: {id: 14, value: BigInt(8192), description: 'Πρόσβαση στο γράφημα'},
    },
    liquids: {
        CAN_SEE_LIQUIDS_DATATABLE: {id: 15, value: BigInt(16384), description: 'Πρόσβαση στα δεδομένα'},
        CAN_STORE_LIQUIDS: {id: 16, value: BigInt(32768), description: 'Δημιουργία Νέου'},
        CAN_EDIT_LIQUIDS: {id: 17, value: BigInt(65536), description: 'Τροποποίηση'},
        CAN_UPDATE_LIQUIDS: {id: 18, value: BigInt(131072), description: 'Ενημέρωση'},
        CAN_DESTROY_LIQUIDS: {id: 19, value: BigInt(262144), description: 'Διαγραφή'},
        CAN_FILTER_LIQUIDS: {id: 20, value: BigInt(524288), description: 'Αναζήτηση'},
    },
    transfers: {
        CAN_SEE_TRANSFERS_DATATABLE: {id: 21, value: BigInt(1048576), description: 'Πρόσβαση στα δεδομένα'},
        CAN_SEE_TRANSFERS_INFO: {id: 22, value: BigInt(2097152), description: 'Προβολή λεπτομερούς αναφοράς'},
    },
    logs: {
        CAN_SEE_LOGS_DATATABLE: {id: 23, value: BigInt(4194304), description: 'Πρόσβαση στα δεδομένα'},
        CAN_SEE_LOGS_INFO: {id: 24, value: BigInt(8388608), description: 'Προβολή λεπτομερούς αναφοράς'},
    },
    actions: {
        CAN_ADD_PRODUCT_TO_CONTAINER: {id: 25, value: BigInt(16777216), description: 'Γέμισμα'},
        CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS: {id: 26, value: BigInt(33554432), description: 'Μεταφορά'},
        CAN_BOTTLE_PRODUCT: {id: 27, value: BigInt(67108864), description: 'Εμφιάλωση'},
        CAN_BLEND_PRODUCT: {id: 28, value: BigInt(134217728), description: 'Σύμμιξη'},
    },
    admins: {
        CAN_SEE_ADMINS_DATATABLE: {id: 29, value: BigInt(268435456), description: 'Πρόσβαση στα δεδομένα'},
        CAN_STORE_ADMINS: {id: 30, value: BigInt(536870912), description: 'Δημιουργία Νέου'},
        CAN_EDIT_ADMINS: {id: 31, value: BigInt(1073741824), description: 'Τροποποίηση'},
        CAN_UPDATE_ADMINS: {id: 32, value: BigInt(2147483648), description: 'Ενημέρωση'},
        CAN_DESTROY_ADMINS: {id: 33, value: BigInt(4294967296), description: 'Διαγραφή'},
    },
};

export default Privileges