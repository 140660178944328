<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-home me-2"></i>Αρχική</h1>
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item active"><i class="fas fa-home me-2"></i>Αρχική</li>
      </ol>
      <div class="row">

        <router-link :to="{name: 'containers'}" class="col-xl-3 col-md-6">
          <div class="card bg-primary text-white mb-4">
            <div class="card-body">{{ menuTitles.containers.title }}
              <div class="card-body-icon"><i class="fa fa-fw fa-whiskey-glass"></i></div>
              <div class=""></div>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="javascript:void(0)">Μετάβαση</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </router-link>

        <router-link :to="{name: 'products'}" class="col-xl-3 col-md-6">
          <div class="card bg-warning text-white mb-4">
            <div class="card-body">{{ menuTitles.products.title }}
              <div class="card-body-icon"><i class="fa fa-fw fa-champagne-glasses"></i></div>
              <div class=""></div>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="javascript:void(0)">Μετάβαση</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </router-link>

        <router-link :to="{name: 'liquids'}" class="col-xl-3 col-md-6">
          <div class="card bg-success text-white mb-4">
            <div class="card-body">{{ menuTitles.liquids.title }}
              <div class="card-body-icon"><i class="fa fa-fw fa-wine-bottle"></i></div>
              <div class=""></div>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="javascript:void(0)">Μετάβαση</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </router-link>

        <router-link :to="{name: 'transfers'}" class="col-xl-3 col-md-6">
          <div class="card bg-danger text-white mb-4">
            <div class="card-body">{{ menuTitles.transfers.title }}
              <div class="card-body-icon"><i class="fa fa-fw fa-arrow-right-arrow-left"></i></div>
              <div class=""></div>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="javascript:void(0)">Μετάβαση</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </router-link>

      </div>
      <div class="row d-none d-lg-flex">
        <div class="col-xl-6">
          <div class="card mb-4">
            <div class="card-header">
              <i class="fas fa-chart-bar me-1"></i>
              Προεπισκόπιση Δεξαμενών
            </div>
            <div class="card-body">
              <div class="containers-chart-container">
<!--                <canvas id="containers-chart" width="100%" height="40"></canvas>-->
                <VueApexCharts height="1000" type="bar" :options="containersChart.options" :series="containersChart.series"></VueApexCharts>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card mb-4">
            <div class="card-header">
              <i class="fas fa-chart-area me-1"></i>
              Προεπισκόπιση Προϊόντων
            </div>
            <div class="card-body">
              <div class="products-chart-container">
<!--                <canvas id="products-chart" width="100%" height="40"></canvas>-->
                <VueApexCharts height="1000" type="bar" :options="productsChart.options" :series="productsChart.series"></VueApexCharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import axios from "axios";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import apiRoutes from "@/router/ApiRoutes";
import menuTitles from "../assets/js/Library/MenuTitles";
import VueApexCharts from "vue3-apexcharts";
export default {
  name: 'DashboardView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {AdminLayout, VueApexCharts},
  data() {
    return {

      productsChart: {
        options: {
          chart: { toolbar: { show: false } }, dataLabels: { enabled: false },
          plotOptions: { bar: { horizontal: true }, },
          xaxis: { categories: [], },
        },
        series: [{ data: [] }]
      },

      containersChart: {
        options: {
          chart: { toolbar: { show: false } }, dataLabels: { enabled: false },
          plotOptions: { bar: { horizontal: true }, },
          xaxis: { categories: [], },
        },
        series: [{ data: [] }]
      },
    }
  },
  mounted() {
    this.getProductsChart();
    this.getContainersChart();
  },
  methods: {
    getProductsChart() {

      axios.get(apiRoutes.products.chart, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.productsChart.series = [{name: "Απόθεμα", data: []}];
        response.data.forEach((liquid) => {
          this.productsChart.options.xaxis.categories.push(liquid.name)
          this.productsChart.series[0].data.push(liquid.total)
        });

      }).catch((error) => errorHandle(error))
    },
    getContainersChart() {
      axios.get(apiRoutes.containers.chart, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.containersChart.series = [{name: "Συνολική Χωρητικότητα", data: []}, {name: "Τωρινή Χωρητικότητα", data: []}];
        response.data.forEach((container) => {
          this.containersChart.options.xaxis.categories.push(container.name)
          this.containersChart.series[0].data.push(container.capacity);
          this.containersChart.series[1].data.push(container.level);
        });
      }).catch((error) => errorHandle(error))
    }

  }
}
</script>

<style scoped>
.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -25px;
  font-size: 5rem;
}

a {
  text-decoration: none;
}


</style>
