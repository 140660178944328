<template>
  <div class="modal fade" ref="privilegesModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-user-shield me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form id="privileges-form">

            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="containers-tab" data-bs-toggle="tab" data-bs-target="#containers" type="button" role="tab" aria-controls="containers" aria-selected="true">Δεξαμενές</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="products-tab" data-bs-toggle="tab" data-bs-target="#products" type="button" role="tab" aria-controls="products" aria-selected="false">Προϊόντα</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="liquids-tab" data-bs-toggle="tab" data-bs-target="#liquids" type="button" role="tab" aria-controls="liquids" aria-selected="false">Συστατικά</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="transfers-tab" data-bs-toggle="tab" data-bs-target="#transfers" type="button" role="tab" aria-controls="transfers" aria-selected="false">Κινήσεις</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="logs-tab" data-bs-toggle="tab" data-bs-target="#logs" type="button" role="tab" aria-controls="logs" aria-selected="false">Αναφορές</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="actions-tab" data-bs-toggle="tab" data-bs-target="#actions" type="button" role="tab" aria-controls="actions" aria-selected="false">Ενέργειες</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="admins-tab" data-bs-toggle="tab" data-bs-target="#admins" type="button" role="tab" aria-controls="admins" aria-selected="false">Διαχειριστές</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="containers" role="tabpanel" aria-labelledby="containers-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.containers">
                    <input class="form-check-input" type="checkbox" role="switch" :id="`privilege-${count}`" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="`privilege-${count}`">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="products" role="tabpanel" aria-labelledby="products-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.products" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="liquids" role="tabpanel" aria-labelledby="liquids-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.liquids" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="transfers" role="tabpanel" aria-labelledby="transfers-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.transfers" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.logs" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="actions" role="tabpanel" aria-labelledby="actions-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.actions" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="admins" role="tabpanel" aria-labelledby="admins-tab">
                <div class="row m-2">
                  <div class="form-check form-switch" v-for="(privilege, index, count) in Privileges.admins" :key="privilege.id">
                    <input class="form-check-input" type="checkbox" role="switch" :id="privilege.id" v-model="form[(privilege.id-1)]">
                    <label class="form-check-label" :for="privilege.id">{{ privilege.description }}</label>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";
import Privileges from "@/assets/js/Library/Privileges";
import Swal from "sweetalert2";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import ModalButtons from "@/assets/js/Library/Modals";

export default {
  name: 'PrivilegesModal',
  computed: {
    Privileges() {
      return Privileges
    }
  },
  components: {VueSelect},
  data() {
    return {
      privileges: {
        canStoreAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_STORE_ADMINS.value) === Privileges.admins.CAN_STORE_ADMINS.value,
        canEditAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_EDIT_ADMINS.value) === Privileges.admins.CAN_EDIT_ADMINS.value,
        canUpdateAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_UPDATE_ADMINS.value) === Privileges.admins.CAN_UPDATE_ADMINS.value,
      },
      title: '',
      button: null,
      modalRef: null,
      privilege: null,
      adminId: 0,
      form: [],
    }
  },
  created() {
    window.editPrivileges = this.editPrivileges;
    window.updatePrivileges = this.updatePrivileges;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.privilegesModal);
    Object.values(Privileges.containers).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.containers).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.products).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.liquids).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.transfers).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.logs).forEach(() => {
      this.form.push(false);
    });
    Object.values(Privileges.actions).forEach(() => {
      this.form.push(false);
    });
  },
  methods: {
    editPrivileges(id) {
      axios.post(ApiRoutes.admins.editPrivileges, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.form = response.data.privilege;
        this.adminId = id;
      }).catch((error) => ErrorHandle(error));
      this.title = 'Προσαρμογή Δικαιωμάτων';
      this.button = ModalButtons.privileges(id);
      this.modalRef.show();
    },
    updatePrivileges() {
      axios.patch(ApiRoutes.admins.updatePrivileges, {id: this.adminId, privileges: this.form}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Επιτυχία',
          text: response.data.message
        })
        this.modalRef.hide();
        this.$emit('data-modified');
      }).catch((error) => ErrorHandle(error));
    }
  }
}
</script>

<style scoped>

</style>