import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@/assets/js/bootstrap.bundle.min.js'

import '@/assets/js/fontawesome.all';

import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App);

app.use(router);

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('token');
    const privilege = localStorage.getItem('privilege');

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: 'login' });
        return;
    }

    if (to.meta.privilege && ((to.meta.privilege & BigInt(privilege)) !== to.meta.privilege)) {
        next({ name: 'login' });
        return;
    }

    if (to.meta.guest && isAuthenticated) {
        next({ name: 'dashboard' });
        return;
    }

    next();
});

app.mount('#app');
