<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i :class="`fas ${menuTitles.products.icon} me-2`"></i>{{ menuTitles.products.title }}</h1>
      <BreadcrumbComponent :title="menuTitles.products.title" :icon="menuTitles.products.icon"/>

      <ProductsTable ref="productsTable"/>

    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import menuTitles from "../../assets/js/Library/MenuTitles";
import ProductsTable from "@/views/Products/ProductsTable.vue";
import BreadcrumbComponent from "@/components/LayoutComponents/BreadcrumbComponent.vue";

export default {
  name: 'ProductsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {BreadcrumbComponent, ProductsTable, AdminLayout},
}
</script>
