<template>
  <admin-layout>
    <div id="layoutError">
      <div id="layoutError_content">
        <main>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="text-center mt-4">
                  <h1 class="display-1">404</h1>
                  <p class="lead">Η σελίδα που ζητήσατε δεν βρέθηκε</p>
                  <router-link :to="{name: 'dashboard'}">
                    <i class="fas fa-arrow-left me-1"></i>
                    Επιστροφή στην αρχική
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div id="layoutError_footer">
        <footer class="py-4 bg-light mt-auto">
          <div class="container-fluid px-4">
            <div class="d-flex align-items-center justify-content-between small">
              <div class="text-muted">Copyright &copy; Your Website 2023</div>
              <div>
                <a href="#">Privacy Policy</a>
                &middot;
                <a href="#">Terms &amp; Conditions</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";

export default {
  name: "NotFoundComponent",
  components: { AdminLayout }
}
</script>

<style scoped>

</style>