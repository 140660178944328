<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i :class="`fas ${menuTitles.transfers.icon} me-2`"></i>{{ menuTitles.transfers.title }}</h1>
      <BreadcrumbComponent :title="menuTitles.transfers.title" :icon="menuTitles.transfers.icon"/>

      <TransfersTable/>

    </div>

  </AdminLayout>
</template>

<script>

import AdminLayout from "@/layouts/AdminLayout.vue";
import menuTitles from "../../assets/js/Library/MenuTitles";
import TransfersTable from "@/views/Transfers/TransfersTable.vue";
import BreadcrumbComponent from "@/components/LayoutComponents/BreadcrumbComponent.vue";

export default {
  name: 'ProductsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {BreadcrumbComponent, AdminLayout, TransfersTable},
}
</script>
