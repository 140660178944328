<template>
  <div class="modal fade" ref="adminsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-user-shield me-2"></i>{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="row my-1 mx-1">
              <div class="col">
                <div class="mb-3">
                  <label for="admin-name" class="form-label">Όνομα</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="admin-name" v-model="form.name">
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="admin-surname" class="form-label">Επώνυμο</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="admin-surname" v-model="form.surname">
                </div>
              </div>
            </div>

            <div class="row my-1 mx-1">
              <div class="col">
                <div class="mb-3">
                  <label for="admin-email" class="form-label">Διεύθυνση E-mail</label>
                  <input @keydown.enter.prevent="handleEnterKey" type="email" class="form-control" id="admin-email" v-model="form.email">
                </div>

              </div>
            </div>

            <div ref="passwordRef" class="row my-1 mx-1">

              <label for="admin-password" class="form-label">Κωδικός Πρόσβασης</label>
              <div class="input-group mb-3">
                <input @keydown.enter.prevent="handleEnterKey" :type="showPassword ? 'text' : 'password'" class="form-control" id="admin-password" v-model="form.password">
                <button class="btn btn-outline-secondary" type="button" @click="togglePassword">
                  <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </button>
              </div>

            </div>
            <div ref="repeatPasswordRef" class="row my-1 mx-1">

              <label for="admin-password-repeat" class="form-label">Επιβεβαίωση Κωδικού Πρόσβασης</label>
              <div class="input-group mb-3">
                <input @keydown.enter.prevent="handleEnterKey" :type="showPassword ? 'text' : 'password'" class="form-control" id="admin-password-repeat" v-model="form.passwordRepeat">
                <button class="btn btn-outline-secondary" type="button" @click="togglePassword">
                  <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </button>
              </div>

            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div v-html="button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {VueSelect} from "vue-select/src";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";

export default {
  name: 'AdminsModal',
  components: {VueSelect},
  data() {
    return {
      privileges: {
        canStoreAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_STORE_ADMINS.value) === Privileges.admins.CAN_STORE_ADMINS.value,
        canEditAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_EDIT_ADMINS.value) === Privileges.admins.CAN_EDIT_ADMINS.value,
        canUpdateAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_UPDATE_ADMINS.value) === Privileges.admins.CAN_UPDATE_ADMINS.value,
        canDestroyAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.admins.CAN_DESTROY_ADMINS.value) === Privileges.admins.CAN_DESTROY_ADMINS.value,
      },
      title: '',
      button: null,
      modalRef: null,
      privilege: null,
      form: {
        id: 0,
        name: '',
        surname: '',
        email: '',
        password: '',
        passwordRepeat: '',
      },
      showPassword: false
    }
  },
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.adminsModal);
  },
  methods: {
    prepareStore() {
      this.title = 'Προσθήκη νέου διαχειριστή';
      this.button = ModalButtons.create;

      this.clearForm();
      this.openModal();
    },
    store() {
      if (!this.privileges.canStoreAdmins) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      if (this.form.password !== this.form.passwordRepeat) {
        Swal.fire({
          title: 'Σφάλμα',
          icon: 'error',
          text: 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι',
        });
        return;
      }

      const containerData = new FormData();
      containerData.append('name', this.form.name);
      containerData.append('surname', this.form.surname);
      containerData.append('email', this.form.email);
      containerData.append('password', this.form.password);
      containerData.append('passwordRepeat', this.form.passwordRepeat);

      axios.post(apiRoutes.admins.store, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
          this.closeModal();
        }
      }).catch((error) => ErrorHandle(error));

      this.$emit('data-modified')
    },
    edit(id) {
      if (!this.privileges.canEditAdmins) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      this.hidePasswordFields();

      this.title = 'Διόρθωση Στοιχείων Διαχειριστή';
      this.button = ModalButtons.update(id)

      axios.post(apiRoutes.admins.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form = response.data;

          this.openModal();

        }
      }).catch((error) => ErrorHandle(error));
    },
    update() {
      if (!this.privileges.canUpdateAdmins) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      if (this.form.password !== this.form.passwordRepeat) {
        Swal.fire({
          title: 'Σφάλμα',
          icon: 'error',
          text: 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι',
        });
        return;
      }

      const containerData = {};
      containerData.id = this.form.id;
      containerData.name = this.form.name;
      containerData.surname = this.form.surname;
      containerData.email = this.form.email;
      containerData.password = this.form.password;
      containerData.passwordRepeat = this.form.passwordRepeat;

      axios.patch(apiRoutes.admins.update, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
          this.closeModal();
        }
      }).catch((error) => ErrorHandle(error));

      this.$emit('data-modified')
    },
    destroy(id) {
      if (!this.privileges.canDestroyAdmins) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα τον διαχειριστή! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.admins.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))

          this.$emit('data-modified')

        }
      });
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    showPasswordFields() {
      this.$refs.passwordRef.classList.remove('d-none');
      this.$refs.repeatPasswordRef.classList.remove('d-none');
    },
    hidePasswordFields() {
      this.$refs.passwordRef.classList.add('d-none');
      this.$refs.repeatPasswordRef.classList.add('d-none');
    },
    clearForm() {
      this.form.id = 0;
      this.form.name = '';
      this.form.surname = '';
      this.form.email = '';
      this.form.password = '';
      this.form.passwordRepeat = '';
    }
  }
}
</script>

<style scoped>

</style>