<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i :class="`fas ${menuTitles.admins.icon} me-2`"></i>{{ menuTitles.admins.title }}</h1>

      <BreadcrumbComponent :title="menuTitles.admins.title" :icon="menuTitles.admins.icon" />

      <AdminsTable ref="adminsTable"/>

    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import menuTitles from "../../assets/js/Library/MenuTitles";
import AdminsTable from "@/views/Admins/AdminsTable.vue";
import BreadcrumbComponent from "@/components/LayoutComponents/BreadcrumbComponent.vue";

export default {
  name: 'AdminsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {BreadcrumbComponent, AdminLayout, AdminsTable},
}
</script>
