import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import ContainersView from "@/views/Containers/ContainersView.vue";
import LiquidsView from "@/views/Liquids/LiquidsView.vue";
import LogsView from "@/views/Logs/LogsView.vue";
import ProductsView from "@/views/Products/ProductsView.vue";
import TransfersView from "@/views/Transfers/TransfersView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import Privileges from "@/assets/js/Library/Privileges";
import AdminsView from "@/views/Admins/AdminsView.vue";
import ResetPasswordView from "@/views/Auth/ResetPasswordView.vue";
import ForgotPasswordView from "@/views/Auth/ForgotPasswordView.vue";

const routes = [
  {path: '/login', name: 'login', component: LoginView, meta: {requiresAuth: false, guest: true}},
  {path: '/reset-password/:token/:email', name: 'resetPassword', component: ResetPasswordView, meta: {requiresAuth: false, guest: true}},
  {path: '/forgot-password', name: 'forgotPassword', component: ForgotPasswordView, meta: {requiresAuth: false, guest: true, test: 'wqe'}},

  {path: '/', name: 'dashboard', component: DashboardView, meta: {requiresAuth: true, privilege: null, guest: false}},
  {path: '/containers', name: 'containers', component: ContainersView, meta: {requiresAuth: true, privilege: Privileges.containers.CAN_SEE_CONTAINERS_DATATABLE.value.value, guest: false}},
  {path: '/products', name: 'products', component: ProductsView, meta: {requiresAuth: true, privilege: Privileges.products.CAN_SEE_PRODUCTS_DATATABLE.value, guest: false}},
  {path: '/liquids', name: 'liquids', component: LiquidsView, meta: {requiresAuth: true, privilege: Privileges.liquids.CAN_SEE_LIQUIDS_DATATABLE.value, guest: false}},
  {path: '/transfers', name: 'transfers', component: TransfersView, meta: {requiresAuth: true, privilege: Privileges.transfers.CAN_SEE_TRANSFERS_DATATABLE.value, guest: false}},
  {path: '/logs', name: 'logs', component: LogsView, meta: {requiresAuth: true, privilege: Privileges.logs.CAN_SEE_LOGS_DATATABLE.value, guest: false}},
  {path: '/admins', name: 'admins', component: AdminsView, meta: {requiresAuth: true, privilege: Privileges.admins.CAN_SEE_ADMINS_DATATABLE.value, guest: false}},
  { name: 'NotFound', path: '/:catchAll(.*)', component: NotFoundView, meta: {requiresAuth: true, privilege: null, guest: false} },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
