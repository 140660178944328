<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i :class="`fas ${menuTitles.logs.icon} me-2`"></i>{{ menuTitles.logs.title }}</h1>
      <BreadcrumbComponent :title="menuTitles.logs.title" :icon="menuTitles.logs.icon"/>

      <LogsTable/>

    </div>

  </AdminLayout>
</template>

<script>

import AdminLayout from "@/layouts/AdminLayout.vue";
import menuTitles from "../../assets/js/Library/MenuTitles";
import LogsTable from "@/views/Logs/LogsTable.vue";
import BreadcrumbComponent from "@/components/LayoutComponents/BreadcrumbComponent.vue";

export default {
  name: 'LogsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {BreadcrumbComponent, AdminLayout, LogsTable},
}
</script>
