let apiPath = '';

const host = window.location.host;
const parts = host.split('.');

if (parts[0] === 'charovas') {
    apiPath = 'https://charovas.tankometric.gr/backend/api';
}

if (parts[0] === 'demo') {
    apiPath = 'https://demo.tankometric.gr/backend/api';
}

// apiPath = 'http://127.0.0.1:8000/api';

const ApiRoutes = {
    login: apiPath + '/login',
    logout: apiPath + '/logout',
    resetPassword: apiPath + '/reset-password',
    forgotPassword: apiPath + '/forgot-password',
    checkPasswordRequest: apiPath + '/check-reset-password',
    containers: {
        datatable: apiPath + '/containers/get',
        store: apiPath + '/containers/store',
        edit: apiPath + '/containers/edit',
        update: apiPath + '/containers/update',
        destroy: apiPath + '/containers/destroy',
        filter: apiPath + '/containers/filter',
        chart: apiPath + '/containers/chart',
        export: {
            excel: apiPath + '/containers/export/excel',
        },
    },
    products: {
        datatable: apiPath + '/products/get',
        store: apiPath + '/products/store',
        edit: apiPath + '/products/edit',
        update: apiPath + '/products/update',
        destroy: apiPath + '/products/destroy',
        filter: apiPath + '/products/filter',
        chart: apiPath + '/products/chart',
    },
    liquids: {
        datatable: apiPath + '/liquids/get',
        store: apiPath + '/liquids/store',
        edit: apiPath + '/liquids/edit',
        update: apiPath + '/liquids/update',
        destroy: apiPath + '/liquids/destroy',
        filter: apiPath + '/liquids/filter',
    },
    transfers: {
        get: apiPath + '/transfers/get',
        view: apiPath + '/transfers/view',
        types: apiPath + '/transfers/types',
        export: {
            excel: apiPath + '/transfers/export/excel',
        }
    },
    logs: {
        get: apiPath + '/logs/get',
        view: apiPath + '/logs/view',
    },
    actions: {
        add: apiPath + '/actions/add',
        transfer: apiPath + '/actions/transfer',
        bottling: apiPath + '/actions/bottling',
        blend: apiPath + '/actions/blend',
    },
    admins: {
        datatable: apiPath + '/admins/get',
        store: apiPath + '/admins/store',
        edit: apiPath + '/admins/edit',
        editPrivileges: apiPath + '/admins/edit/privileges',
        update: apiPath + '/admins/update',
        updatePrivileges: apiPath + '/admins/update/privileges',
        destroy: apiPath + '/admins/destroy',
    }

}
export default ApiRoutes;