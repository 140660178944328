import Swal from "sweetalert2";
import router from "@/router"

const ErrorHandle = (error) => {
    if (error.response) {
        if (error.response.status === 422) {
            Swal.fire({
                title: 'Σφάλμα',
                icon: 'error',
                text: error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
            })
            return;
        }
        if (error.response.status === 401 || error.response.status === 403) {
            Swal.fire({
                title: 'Σφάλμα Διαπιστευτηρίων',
                icon: 'error',
                text: 'Δεν έχετε τα απαραίτητα δικαιώματα για να εκτελέσετε αυτήν την ενέργεια. Πραγματοποιείται αυτόματη αποσύνδεση από το σύστημα.',
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                localStorage.clear();
                location.href = '/login';
            });
            return
        }
        Swal.fire({
            title: 'Πρόβλημα στο server',
            icon: 'error',
            text: 'Προέκυψε ένα απροσδιόριστο πρόβλημα στον server. Παρακαλούμε συνδεθείτε ξανά στο σύστημα. Ζητούμε συγνώμη για την αναστάτωση.'
        }).then(() => {
            localStorage.clear();
            location.href = '/login';
        });
    }
}

export default ErrorHandle;